import React from "react";
import Logo from "../assets/masLogo.webp";

function Landing() {
  const navlinks = [
    {
      title: "Packages and Pricing",
      link: "/packages",
    },
    {
      title: "Our Clients",
      link: "/clients",
    },
    {
      title: "Avail now",
      link: "/avail",
    },
    {
      title: "Contact us",
      link: "/contact",
    },
  ];

  const services = [
    {
      title: "Tax (BIR) Compliance",
      description:
        "Let us do your tax filings and be worry free. Also using our proprietary technology, you will be able to gather all your documents in one place without having to worry if you misplace your copy of tax compliance.",
      image: "",
    },
    {
      title: "Accounting",
      description:
        "Our accounting procedure is done purely online, just upload your receipts and we will take care of it. We will also provide you with a dashboard where you can see your financial statements and other reports.",
      image: "",
    },
    {
      title: "Payroll",
      description:
        "Do you find payroll computation very tedious? Let us do it for you. We will also provide you with a dashboard where you can see your payroll reports and payslips.",
      image: "",
    },
    {
      title: "Business Registration",
      description:
        "Just upload your documents and we will take care of your business registration. We will also provide you with a dashboard where you can see your business registration documents and other reports.",
      image: "",
    },
  ];

  const packages = [
    {
      title: "Zero Filing Package",
      description:
        "Tax (BIR) Compliance for zero transactions businesses. Zero filing with BIR is done to ensure compliance and zero open cases prior to actual operation.",
      image: "",
      price: "Php 1,000 / month",
    },
    {
      title: "Small Business Package",
      description:
        "Accounting and Tax Compliance for small operating businesses. This is applicable for businesses with 1 to 10 transactions a day, or maximum of 300 transactions per month.",
      image: "",
      price: "Php 5,000 / month",
    },
    {
      title: "Medium Business Package",
      description:
        "Accounting and Tax Compliance for medium sized entities. This is applicable for business with 11 to 50 transactions a day or a maximum of 1,500 transactions per month.",
      image: "",
      price: "Php 25,000 / month",
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap items-center justify-around bg-green-800 text-white p-4 ">
        <div>
          <img src={Logo} alt="Logo" className="h-12 lg:h-16 mb-4 lg:mb-0" />
        </div>
        {/*   <div className="">
          <ul className="flex gap-x-4 ">
            {navlinks.map((link) => (
              <li
                className="hover:bg-gray-100 hover:text-black px-4 py-2 lg:text-sm text-xs"
                key={link.title}
              >
                <a href={link.link}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div> */}
      </div>

      <div
        className="hero-section h-[500px] bg-[url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]
      bg-cover bg-center bg-no-repeat flex items-center
      "
      >
        <div className="right-side w-full h-full  bg-black bg-opacity-60 flex flex-col items-start px-8 py-4 text-left justify-center text-white font-bold text-3xl">
          <div>Get rid of your worries on compliance</div>
          <div className="font-normal mt-4 ">
            Easily avail of our services by just signing up with our packages.
          </div>
          <button className="bg-green-800 text-white px-4 py-2 mt-4 rounded-md">
            Avail Now
          </button>
        </div>
      </div>

      <div className="bg-gray-100 p-8">
        <div className="text-3xl font-bold p-4 mb-8">Our Services</div>
        <div
          key={services.title}
          className="flex flex-row flex-wrap items-center justify-around gap-2"
        >
          {services.map((service) => (
            <div className="p-2 rounded shadow-md flex flex-col items-center justify-between bg-white rounder border h-[300px] w-[300px] ">
              <div>
                <div className="font-bold text-2xl ">{service.title}</div>
                <div className="text-left text-sm mt-4 p-4">
                  {service.description}
                </div>
              </div>
              <button
                className="
              bg-green-500 rounded py-2 px-4 mt-4 text-white
              "
              >
                Avail now
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <div className="font-bold text-2xl p-4 mb-8">Our Service Packages</div>
        <div className="flex flex-wrap gap-2 items-center justify-around">
          {packages.map((item) => (
            <div
              className="h-[400px] w-[300px] flex flex-col items-center py-8 px-4 justify-between bg-gray-100 shadow-md rounded border"
              key={item.title}
            >
              <div className="font-bold">{item.title}</div>
              <div className="text-left mt-4 mb-4 ">{item.description}</div>
              <div className="font-bold">{item.price}</div>
              <button
                className="
              bg-green-500 rounded py-2 px-4 mt-4 text-white
              "
              >
                Avail now
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-800 min-h-[200px] text-white lg:grid grid-cols-2">
        <div className="text-left p-8">
          <div>Contact Us Now!!</div>
          <div>Magat Accounting Services</div>
          <div>5th Floor Phinma Plaza Rockwell Center Makati City</div>
          <div>Send an SMS: 0917 9958 168</div>
          <div>Email us: sales@magatcpa.com</div>
        </div>
        <div>
          <div className="text-gray-500 mt-4">Privacy Information</div>
          <div className="text-xs text-left mt-4 text-gray-500 p-4">
            Welcome to Magat Accounting Services. We prioritize the security and
            privacy of your information. When you visit our website, we may
            collect and process personal and non-personal data for the purpose
            of providing you with our accounting services. Your information is
            handled with utmost care and in accordance with applicable data
            protection laws. We do not sell your data to third parties, and it
            is only shared when necessary for the provision of our services or
            as required by law. By continuing to use our website, you consent to
            the terms outlined in our Privacy Policy. If you have any questions
            or concerns, please refer to our full Privacy Policy or contact us
            at sales@magatcpa.com.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
